import revive_payload_client_4sVQNw7RlN from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_KAumv2pUni from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "C:/Users/ashle/OneDrive/Desktop/ash/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_gab9g3XuEx from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/nuxt-aos/dist/runtime/plugin.js";
import vuetify_no_client_hints_kyYn5AnnAK from "C:/Users/ashle/OneDrive/Desktop/ash/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "C:/Users/ashle/OneDrive/Desktop/ash/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_gab9g3XuEx,
  vuetify_no_client_hints_kyYn5AnnAK,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]